
.dropdown
    width: 100%
    position: absolute
    z-index: 200
    overflow-y: scroll
    max-height: 300px
    -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2)
    -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2)
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2)
    .list-group-item
        width: 100%

.dropdownBg
    background: #000
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    opacity: 0.1
    z-index: 100
    
.dropdown_bg-hide
    display: none

.text_input
    z-index: 150
    position: relative

.hide
    visibility: hidden

.input_container
    position: relative