@import '../app/_fonts.sass'
@import '../favoriteButton/favoriteButton.sass'
@import './colors.sass'
@import '../common/multiValueSelect/dropDownListItem.sass'
@import '../common/loader/loader.sass'
@import '../common/header/header.sass'
@import '../VkLoginButton/vkLoginButton.sass'

.input_error_message
    color: #b95555
    display: block

.btn-secondary
    background-color: $purple
    max-width: 200px
    width: 100%
    border: none

.btn-secondary:hover
    background-color: $dark_purple

.nav_link.btn-secondary
    padding: 0px

.btn-secondary a
    display: block
    text-decoration: none
    color: #fff
    padding: 0.5em
    border-radius: 0.25em

.group_button
    width: auto

.input-group> .select_button
    flex: 0 0 120px

.date-input
    width: auto

.brand-link a
    text-decoration: none
    color: white

.center
    display: block
    margin: auto

.nav_link
    max-width: 200px
    width: 100%

.form_container
    width: 100%
    max-width: 450px
    margin: auto

.results .result_item
    min-width: 300px

.result_item .row
    margin-bottom: 5px

.badge
    margin: 5px

.marked_items .badge
    font-size: 1em
    background-color: $purple
    .delete_item_button
        cursor: pointer
        border: none
    :hover
        color: $light_blue

.delete_item_button
    font-family: sans-serif

.list__item-active
    background-color: $purple
    color: #fff

.music-style_badge
    background-color: $purple
    font-size: 1em

.avert-type_container .dropdown
    overflow-y: auto

.dropdown
    .list-group-item:hover
        background-color: $purple
        color: #fff

.prop_name
    word-wrap: normal

.edit_mode_on
    display: none

.profile_info_field
    min-height: 50px

.footer
    min-height: 200px

.avatar_image_container
    overflow-y: hidden
    border-radius: 50%

.avatar_image
    display: block
    width: 100%

.profileAvatar_image
    display: block
    width: 100%

.alert
    position: fixed
    bottom: 0vh
    width: 100%
    z-index: 250

.canvas
    display: block
    margin: auto
    background-color: #c7c7c7

.hide
    visibility: hidden
    position: absolute

.loader_container
    width: 100%
    height: 250px
    display: flex
    justify-content: center
    align-items: center

.contact_item
    display: flex
    padding: 10px 0

.contact_icon_container
    height: 1.5em
    width: 1.5em
    margin-right: 10px
    svg
        display: block
        height: 100%
        fill: $dark_text
    :hover
        fill: $dark_purple

.input_message
    line-height: 2em
