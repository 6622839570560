@import '../../app/_colors.sass'

.logo_description
    font-size: 0.7em
    display: block

.header_navbar
    background: linear-gradient(180deg, #4D2C91 0%, #7E57C2 100%)
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25)
    .navbar-nav
        .nav-item
            a
                color: white
            a:hover, a.active
                color: $light_blue
            a:active
                color: $blue

    .navbar-toggler .menu_icon
        display: block
        fill: white
        width: 1.5em
        height: 1.5em

    .navbar-toggler .menu_icon.show
        fill: $light_blue


.header
    position: fixed
    z-index: 250
    width: 100%
    top:0px




