.vk_login_button
    background-color: #0077FF
    color: #fff
    width: 200px
    height: 40px
    padding: 10px
    border-radius: 10px
    border: none
    font: 500 18px "Roboto"

.vk_login_button_logo_container
    height: 24px
    width: 24px
    margin-right: 10px
    svg
        display: block
        height: 100%
        fill: #fff
